import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In today’s American Banker, CFSI’s President and Chief Executive Jennifer Tescher extols the potential virtues of government benefit cards as a means toward financial capability among the un- and underbanked. While Team PayPerks lives and breathes this stuff, two of the facts referenced surprised even us:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`In January `}{`[2013]`}{` alone, 62 million people received \\$70 billion in Social Security and disability benefits`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`States pay out nearly \\$13 billion of food stamps benefits annually to 23 million households`}</p>
      </li>
    </ul>
    <p>{`We’ve always argued that government benefits were a scalable channel through which to reach the un- and underbanked but these numbers help us size just how scalable our impact can be. As Jennifer explains:
“One of the most exciting developments on this `}{`[government benefits]`}{` front is the Treasury Department's decision to add a web- and mobile-based financial education hub to Direct Express, the default payment card for federal benefit recipients. Cardholders will have the chance to win cash prizes by engaging in financial education modules. The effort, a partnership between the government, card issuer Comerica Bank, MasterCard, and technology start-up PayPerks, represents the kind of public-private collaboration that will be required to turn government payments into a teachable moment.”`}</p>
    <p>{`Couldn’t have said it better myself!`}</p>
    <p>{`Check out the full article here: `}<a parentName="p" {...{
        "href": "http://www.americanbanker.com/bankthink/government-benefit-cards-can-open-doors-to-banking-system-1057231-1.html?pg=1"
      }}>{`http://www.americanbanker.com/bankthink/government-benefit-cards-can-open-doors-to-banking-system-1057231-1.html?pg=1`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      